const backToTop= document.querySelector(".backtotop");

// reduced motion check
const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
let scrollType = 'smooth';
if (!reducedMotion || reducedMotion.matches) {
	scrollType = 'auto'; //reduced motion
} else {
	//normal
}
// -------------------

//offset
let backToTopOffset = backToTop.getAttribute('data-wd-offset');
if (!backToTopOffset) {
	backToTopOffset = window.innerHeight;
}

window.addEventListener('resize', function(){
	backToTopOffset = window.innerHeight;
	//console.log('resize '+ backToTopOffset);	
});
//console.log(backToTopOffset);
// -------------------

// show or hide
const backToTopVisibility = () => {
	if (window.scrollY > backToTopOffset) {
		backToTop.classList.add('show');
	} else {
		backToTop.classList.remove('show');
	}
};

document.addEventListener("scroll", () => {
	backToTopVisibility();
});
// -------------------

// scroll to top
backToTop.addEventListener("click", () => {
	window.scrollTo({
		top: 0,
		behavior: scrollType
	});
});
// -------------------