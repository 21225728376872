//import css
import "./scss/main.scss";

//import components js
import "./components/aggiornamento/aggiornamento.js";
import "./components/backtotop/backtotop.js";
import "./components/breadcrumbs/breadcrumbs.js";
import "./components/button/button.js";
import "./components/footer/footer.js";
import "./components/header/header.js";
import "./components/headerSlim/headerSlim.js";
import "./components/hero/hero.js";
import "./components/horizontal-text/horizontal-text.js";
import "./components/nav/nav.js";
import "./components/swiper/swiper.js";
import "./components/swiperSlide/swiperSlide.js";
import "./components/text-img/text-img.js";

import "./js/browser-detect";
import "./js/lazyload";
