let navToggler = document.getElementsByClassName('toggler');
let layer = document.getElementsByClassName('nav-overlay');
let menu = document.getElementsByClassName('nav');
let closer = document.getElementsByClassName('close-nav');

navToggler[0].addEventListener('click', function() {
	layer[0].classList.add("opened");
	menu[0].classList.add("opened");
}, false);

closer[0].addEventListener('click', function() {
	layer[0].classList.remove("opened");
	menu[0].classList.remove("opened");
}, false);
