import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
let autoplaySettings;
const isHero = document.getElementsByClassName('swiper-carousel hero-swiper');
if ((isHero.length) == 0) {
	autoplaySettings = false;
}else {
	autoplaySettings = {
		delay: 5000,
		disableOnInteraction: false,
	}
}
const swiper = new Swiper('.swiper-carousel', {

	// configure Swiper to use modules
	modules: [Autoplay, Navigation, Pagination],
	loop: true,
	autoplay: autoplaySettings,
	pagination: {
		el: '.swiper-pagination',
	},
	// Navigation arrows
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
});
