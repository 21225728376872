//browser detect
const { detect } = require('detect-browser');
const browser = detect();
// handle the case where we don't detect the browser
if (browser) {
  let browserOs = browser.os;
  let browserName = browser.name;
  let browserVersion = browser.version;

  browserOs = browserOs.replace(/\s/g, '').toLowerCase();
  browserName = browserName.replace(/\s/g, '').toLowerCase();
  browserVersion = browserVersion.split('.'); // only major version

  document.body.classList.add(browserOs);
  document.body.classList.add(browserName);
  document.body.classList.add('v' + browserVersion[0]);
}
